@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: onest;
  src: url(assets/fonts/Onest.ttf);
}
@font-face {
  font-family: dmsans;
  src: url(assets/fonts/DMSans.ttf);
}


@layer base {
  * {
    @apply box-border p-0 m-0 list-none outline-none font-onest;
  }
  :root {
    --aimagix-primary: #6446EE;
    --aimagix-dark: #111111;
    --aimagix-dark-1d: #1d1d1f;
    --aimagix-darkgray: #333333;
    --aimagix-gray-66: #666666;
    --aimagix-gray-c1: #c1c5cb;
    --aimagix-gray-e8: #e8e8e8;
    --aimagix-gray-fa: #FAFAFA;
    --aimagix-white: #ffffff;
    --aimagix-red: #e32c2b;
    --aimagix-green: #30a407;
    --aimagix-orange: #fc7145;
  }

  html {
    @apply text-[62.5%] max-3xl:text-[48%] max-2xl:text-[43%] max-xl:text-[45%] max-md:text-[50%] max-xs:text-[43%];
  }

  body {
    @apply max-w-full overflow-x-hidden;
  }

  img,
  svg {
    width: 100%;
    height: 100%;
  }
}

@layer base {
  ul,
  ol {
    list-style: revert;
  }
}
@layer components {
  .border-red {
    @apply border border-red-600;
  }
  .border-blue {
    @apply border border-blue-600;
  }
  .common-y-space{
    @apply py-[15rem]
  }
  .terms-paragraph{
    @apply text-[1.8rem] font-dmsans font-normal text-aimagix-gray-66
  }
  .terms-paragraph span{
    @apply text-[1.8rem] font-dmsans font-semibold text-aimagix-dark-1d
  }
  .terms-paragraph a{
    @apply text-[1.8rem] font-dmsans font-normal text-aimagix-primary underline
  }
  .terms-heading{
    @apply text-[3.4rem] font-dmsans font-semibold text-aimagix-dark-1d mb-[2rem]
  }
  .terms-list-ul > li{
    @apply text-[1.8rem] font-dmsans font-normal text-aimagix-gray-66 list-disc list-inside mb-4
  }
  .terms-list-ul > li span{
    @apply text-[1.8rem] font-dmsans font-semibold text-aimagix-dark-1d
  }
  .terms-list-ul > li a{
    @apply text-[1.8rem] font-dmsans font-normal text-aimagix-primary underline
  }
  .terms-list-ol > li{
    @apply text-[1.8rem] font-dmsans font-normal text-aimagix-gray-66 list-decimal list-inside mb-4
  }
  .terms-list-ol > li span{
    @apply text-[1.8rem] font-dmsans font-semibold text-aimagix-dark-1d
  }
  .terms-list-ol > li a{
    @apply text-[1.8rem] font-dmsans font-normal text-aimagix-primary underline
  }
  .terms-list-ol-2 > li{
    @apply text-[2.2rem] font-dmsans font-semibold text-aimagix-dark-1d list-decimal list-inside mb-4
  }
  .terms-list-ol-2 > li span{
    @apply text-[2.2rem] font-dmsans font-semibold text-aimagix-dark-1d
  }
  .terms-list-ol-2 > li a{
    @apply text-[2.2rem] font-dmsans font-normal text-aimagix-primary underline
  }
  .terms-list-ol-3 > li{
    @apply text-[1.8rem] font-dmsans font-medium text-aimagix-dark list-decimal list-inside mb-[2.5rem]
  }
  .terms-list-ol-3 > li span{
    @apply text-[1.8rem] font-dmsans font-semibold text-aimagix-dark-1d
  }
  .terms-list-ol-3 > li p{
    @apply text-[1.8rem] font-dmsans font-normal text-aimagix-gray-66 mt-3
  }
  .terms-list-ol-3 > li a{
    @apply text-[1.8rem] font-dmsans font-normal text-aimagix-primary underline
  }
  .privacy-heading{
    @apply text-[2.8rem] font-dmsans font-medium text-aimagix-dark-1d mb-[2rem]
  }
}
.primary-gradient{
  background: linear-gradient(270deg, #5852FF 0%, #643BE7 100%);
}
.gradient-text{
  background: linear-gradient(89.79deg, #754EFF -19.13%, #FF7A00 117.87%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.book-a-demo-bg-gradient{
  background: linear-gradient(270deg, #5852FF 0%, #643BE7 100%);

}
.banner-bg{
  background-image: url(assets/images/banner-bg.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% 90%;
}
.triangle-pattern-privacy:after{
  content: "";
  width: 30%;
  height: 70vh;
  background-image: url(assets/images/triangle-patterm.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
}
.triangle-pattern-left:after{
  content: "";
  width: 20%;
  height: 100%;
  background-image: url(assets/images/triangle-patterm.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}
.triangle-pattern-right:after{
  content: "";
  width: 20%;
  height: 100%;
  background-image: url(assets/images/triangle-patterm.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  right: 0;
  top: 0;
  rotate: 180deg;
}
.banner-left-gradient::before{
  content: "";
  filter: blur(10rem);
  height: 50vh;
  display: block;
  position: absolute;
  width: 30%;
  top: 50%;
  left: -20%;
  transform: translateY(-50%);
  background: conic-gradient(from 6.35rad,#F3DCE1,#CEBCF9);
  opacity: 60%;
  filter: blur(10rem);
  z-index: 0;
}
.banner-right-gradient::after{
  content: "";
  filter: blur(10rem);
  height: 50vh;
  display: block;
  position: absolute;
  width: 30%;
  top: 50%;
  right: -25%;
  transform: translateY(-50%);
  background: conic-gradient(from 6.35rad,#F3DCE1,#CEBCF9);
  opacity: 60%;
  filter: blur(10rem);
  z-index: 0;
}
.banner-star:before{
  content: "";
  width: 7.3rem;
  height: 7.3rem;
  background-image: url(assets/images/star.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: -25%;
  left: -4%;
}
.get-started-bg{
  background: linear-gradient(270deg, #5852FF 0%, #643BE7 100%);
}
.get-started-pattern{
  background-image: url(assets/images/get-started-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.seamless-gradient{
  background-image: url(assets/images/seamless-gradient.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
}
.manufacturing-right-gradient::after{
  content: "";
  filter: blur(10rem);
  height: 50vh;
  display: block;
  position: absolute;
  width: 30%;
  bottom: 0;
  right: -25%;
  background: conic-gradient(from 6.35rad,#F3DCE1,#CEBCF9);
  opacity: 60%;
  filter: blur(10rem);
  z-index: 0;
}
.floating-menu-bg{
  -webkit-backdrop-filter: blur(.5625rem);
    backdrop-filter: blur(.5625rem);
    background-color: #f8f6ff86;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.book-a-demo-main{
  width: calc(100% - 46.3rem);
}
.book-a-demo-bg{
  background-image: url(assets/images/book-a-demo-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
}
.cus-select__control{
  border: none;
  border-bottom: 1px solid #666666 !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}
.cus-select__indicator-separator {
  display: none;
}
.cus-select__placeholder,
.cus-select__single-value,
.cus-select__input-container  {
  font-size: 1.8rem !important;
}
.cus-select__option {
  font-size: 1.8rem !important;
}
.cus-select__option--is-selected {
  background-color: var(--ntpc-primary) !important;
  color: var(--ntpc-white) !important;
}
.cus-select__option--is-focused{
  background-color: #00416741;
}
.cus-select__menu-portal {
  z-index: 11 !important;
}
.cus-select-border-none .cus-select__control{
  border: 0px !important;
}
.cus-select-bg-none .cus-select__control{
  background-color: transparent !important;
}
.cus-select__multi-value{
  background: #ffffff !important;
  border: 1px solid #111111;
  border-radius: 35px !important;
  padding: 0.1rem 1.8rem !important;
}
.cus-select__multi-value__label{
  font-size: 2.1rem !important;

}
.cus-select__multi-value__remove{
  width: 35px !important;
}
.cus-select__multi-value__remove:hover{
  background-color: transparent !important;
    color: #6446EE !important;
}
@keyframes lineSlide {
  0% {
    top: 0;
  }
  100% {
    top: 100%;
  }
}

.animate-lineSlide {
  animation: lineSlide 1.5s ease-in-out infinite;
}
.btn-loader {
  width: 100%;
  height: 100%;
  border: 3px solid var(--aimagix-white);
  border-bottom-color: var(--aimagix-primary);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}